/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr';

html, body {
  height: 100%;
  background: #eee !important;
}

.card {
  margin-bottom: 15px;
}
